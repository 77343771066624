module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Anderssons Bilservice","short_name":"Anderssons Bils.","start_url":"/","background_color":"#000000","theme_color":"#cd003a","display":"standalone","icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"265771acfb48d9aa3e74f7fa3134c564"},
    },{
      plugin: require('../node_modules/gatsby-plugin-asset-path/gatsby-browser.js'),
      options: {"plugins":[],"fileTypes":["js","css","map","png"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://anderssonsbilservice.se"},
    },{
      plugin: require('../node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"13","matomoUrl":"https://matomo.accodeing.com","siteUrl":"https://anderssonsbilservice.se"},
    },{
      plugin: require('../node_modules/@accodeing/gatsby-theme-heimr/gatsby-browser.js'),
      options: {"plugins":[],"manifest":{"name":"Anderssons Bilservice","short_name":"Anderssons Bils.","background_color":"#000000","theme_color":"#cd003a"},"siteUrl":"https://anderssonsbilservice.se","matomoSiteId":"13"},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
